import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  FilterMetaData,
  FilterOption,
  FilterTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {PAYMENT_METHOD_I18N_KEY, PAYMENT_STATUS_I18N_KEY, PaymentStatus} from '../../models/billable_item';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {PosSupplierOrdersService} from '../../services/pos-supplier-orders.service';
import {SupplierOrder} from '../../models/pos_supplier_order';


@Component({
  selector: 'app-pos-supplier-orders-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class PosSupplierOrdersListComponent extends ItemSimpleFiltrableListAbstractComponent<SupplierOrder>
  implements OnInit, OnDestroy, AfterViewInit {
  // TODO: rename to sales, not orders
  // TODO: implement filters like, payment status
  // TODO: be able to pass a user, and get all his orders

  pageTitleI18N = 'SUPPLIER_ORDERS';

  urlBaseDetailItem = 'pos/supplier_orders';

  displayedColumnsMetaData = {
    shortPrettyId: new DisplayedColumnMetaData('INTERNAL_ID', DisplayedColumnTypes.text),
    documentNumber: new DisplayedColumnMetaData('DOCUMENT_NUMBER', DisplayedColumnTypes.text),
    supplierName: new DisplayedColumnMetaData('SUPPLIER', DisplayedColumnTypes.text),
    createdAt: new DisplayedColumnMetaData('DATE', DisplayedColumnTypes.date),
    documentType: new DisplayedColumnMetaData('TYPE', DisplayedColumnTypes.calculated),
    totalAmountWithTax: new DisplayedColumnMetaData('TOTAL_AMOUNT', DisplayedColumnTypes.currency),
  };

  filtersMetaData = {
    // TODO: document type
    supplier: new FilterMetaData('SUPPLIER', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
    paymentMethod: new FilterMetaData(
      'PAYMENTS.PAYMENT_METHOD',
      FilterTypes.select_single,
      [
        new FilterOption('default_null_filter', 'PAYMENTS.ALL')
      ]),
    paymentStatus: new FilterMetaData(
      'PAYMENTS.PAYMENT_STATUS',
      FilterTypes.select_single,
      [
        new FilterOption('default_null_filter', 'PAYMENTS.ALL'),
        new FilterOption(PaymentStatus.Paid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Paid]),
        new FilterOption(PaymentStatus.PendingAndPartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
        // new FilterOption(PaymentStatus.Pending, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
        // new FilterOption(PaymentStatus.PartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.PartiallyPaid]),
        new FilterOption(PaymentStatus.Unknown, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Unknown]),
        new FilterOption(PaymentStatus.AdvancePayment, PAYMENT_STATUS_I18N_KEY[PaymentStatus.AdvancePayment]),
      ]),
    documentType: new FilterMetaData(
      'DOCUMENT_TYPE',
      FilterTypes.select_single,
      [
        new FilterOption('default_null_filter', 'PAYMENTS.ALL'),
        new FilterOption('invoic', 'INVOICE'),
        new FilterOption('ticket', 'TICKET'),
      ]),
  };

  primaryFab = null;
  secondaryFab = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosSupplierOrdersListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    private userService: UsersService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected posSupplierOrdersService: PosSupplierOrdersService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      posSupplierOrdersService,
      nativeInterfacesService,
      dialog,
      media$,
      userService,
      privacyModeService
    );

    // TODO: move to abstract class
    const enabledPaymentMethods = this.userService.getCustomConfig().get_payment_methods();
    const filterOptionsPaymentMethod = [];
    filterOptionsPaymentMethod.push(new FilterOption('default_null_filter', 'PAYMENTS.ALL'));
    for (const paymentMethod of enabledPaymentMethods) {
      filterOptionsPaymentMethod.push(new FilterOption(paymentMethod, PAYMENT_METHOD_I18N_KEY[paymentMethod]));
    }

    this.filtersMetaData.paymentMethod = new FilterMetaData(
      'PAYMENTS.PAYMENT_METHOD',
      FilterTypes.select_single,
      filterOptionsPaymentMethod);
  }

  ngOnInit(): void {
    if (this.userService.userMe && this.userService.userMe.workshop) {
      // TODO: this shluld not happen, we should wait for the user to be loaded (maybe on a lodcing screen?)
      // tslint:disable-next-line
      this.defaultFilters['workshop_id'] = this.userService.userMe.workshop;
    }

    if (this.parentId) {
      this.addButtonI18N = 'ADD_SUPPLIER_ORDER';
      // No need to show supplier column, we are already on a supplier detail / list
      delete this.displayedColumnsMetaData.supplierName;
      delete this.filtersMetaData.supplier;
    } else {
      this.populateSupplierFilter();
    }

    super.ngOnInit();

    // TODO: remove, if parentId is not null, just create a ticket for that user
    if (this.parentId) {
      this.primaryFab = null;
    }

    if (this.dialogRef != null) {
      this.dialogMode = true;
      if (this.data.mode === 'floating-selector') {
      }
    } else {}
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'fab_add_new_order') {
      this.router.navigate(['/pos/supplier_orders/create']);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  populateSupplierFilter(): void {
    this.userService.getSuppliers().subscribe(suppliers => {
      const filterOptions = [new FilterOption('', 'All')];
      for (const supplier of suppliers) {
        filterOptions.push(new FilterOption(supplier.id, supplier.name));
      }
      this.filtersMetaData.supplier = new FilterMetaData('SUPPLIER', FilterTypes.select_single, filterOptions);
    });
  }
}
