import {AbstractChildItem} from './abstract_child_item';
import {Product} from './pos_product';
import {BillableItem} from './billable_item';


export class SupplierOrder extends BillableItem {
  supplier: string;
  supplierName: string;

  documentNumber: string;
  createdAt: string;
  // itemCount: number;
  // totalCost: number;
}

export class SupplierOrderItem extends AbstractChildItem<SupplierOrder> {
  parentRelName = 'supplierOrder';
  product: string;
  productCode: string;

  price = 0;
  // TODO: don't hardcode vat
  vat = 0.21;

  qty = 1;

  static totalCost(orderItem: SupplierOrderItem): number {
    // We should make this method static because when we get all the items from the service (api), we end up with a list of objects
    // not a list of OrderItems, so we can't access methods from those simple objects
    orderItem.price = Number(orderItem.price);
    orderItem.vat = Number(orderItem.vat);
    return (orderItem.price + (orderItem.price * orderItem.vat)) * orderItem.qty;
  }

  static createFromProduct(product: Product): SupplierOrderItem {
    const noi = new SupplierOrderItem();
    noi.name = product.name;
    // noi.cost = product.cost;
    // console.log('createFromProduct', product.cost, product.vat);
    noi.price = product.cost;
    noi.vat = product.vat;
    noi.product = product.id;
    return noi;
  }
}
