import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {Validators} from '@angular/forms';
import {PosProductsListComponent} from '../../pages/pos-products-list/pos-products-list.component';
import {Product} from '../../models/pos_product';
import {ProgressDialogComponent} from '../dialogs/progress-dialog-component/progress-dialog.component';
import {PosProductsDetailComponent} from '../../pages/pos-products-detail/pos-products-detail.component';
import {SimpleTextDialogComponent} from '../dialogs/simple-text-dialog-component/simple-text-dialog.component';
import {PosProductService} from '../../services/pos-products.service';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MoneyService} from '../../services/money.service';
import {UsersService} from '../../services/users.service';
import {PosSupplierOrdersItemService} from '../../services/pos-supplier-orders-item.service';
import {SupplierOrderItem} from '../../models/pos_supplier_order';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-pos-supplier-order-item-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PosSupplierOrderItemListComponent extends ChildItemEditableListAbstractComponent<SupplierOrderItem> implements OnInit {
  parentApiRelName = 'object_id';

  @Output() itemsUpdated = new EventEmitter();

  addItemLiteralI18n = 'ADD_LINE';

  @Input() showFooter = true;
  @Input() parentApiContentTypeName; // Accepted: order or bikeservicesheet

  orderItems$: Observable<SupplierOrderItem[]>;

  fields = {
    name: {
      // tslint:disable-next-line:max-line-length
      metadata: new ChildItemFieldMetadata('CONCEPT', 'ADD_CONCEPT', ChildItemFieldTypes.text, '---', true, [Validators.required], false),
      mainInput: true,
      w: 45,
      align: 'start'
    },
    // TODO: show as percentatge, and don't hardcode 21, use business default, add validators also
    vat: {
      metadata: new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, 0.21, true, null, false, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    price_with_vat_pu: {
      metadata: new ChildItemFieldMetadata('PRICE_PU', 'ADD_PRICE', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    qty: {
      metadata: new ChildItemFieldMetadata('QUANTITY', 'ADD_QUANTITY', ChildItemFieldTypes.integer, 1, true, null, false),
      mainInput: false,
      w: 8,
      align: 'end'
    },
    price_with_vat_total: { // This price has also the quantity
      metadata: new ChildItemFieldMetadata('TOTAL', 'ADD_PRICE', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    more_opts: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.action, null, true, null, false),
      mainInput: false,
      w: 10,
      align: 'end'
    },
    price: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
    product: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.foreign_key, null, false, null, false, true),
      mainInput: false
    },
    cost: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<SupplierOrderItem>;
  dataSource = new MatTableDataSource();

  defaultVat: number;

  expandedFields: { [key: string]: Set<string> } = {};

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    protected posSupplierOrdersItemService: PosSupplierOrdersItemService,
    private posProductService: PosProductService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected moneyService: MoneyService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, posSupplierOrdersItemService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    this.defaultVat = Number(this.usersService.business.defaultVat);

    // tslint:disable-next-line:max-line-length
    // this.fields.vat.metadata = new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.percent, this.defaultVat, false, null, false, true)

    super.ngOnInit();

    this.orderItems$ = this.posSupplierOrdersItemService.getGenericChilds(this.parent.id, this.parentApiContentTypeName);
    this.orderItems$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x;
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

  }

  createNewItemInstance(): SupplierOrderItem {
    const item = new SupplierOrderItem();
    item.id = this._getID(item);
    item.vat = this.defaultVat;
    return item;
  }

  getTotalCostItem(orderItem: SupplierOrderItem): number {
    return SupplierOrderItem.totalCost(orderItem);
  }

  getTotalCost(): number {
    if (this.items != null) {
      return this.items.reduce((sum, current) => sum + (SupplierOrderItem.totalCost(current) || 0), 0);
    }
    return 0;
  }

  getInputTypeCalculated(field: string): string {
    if (field === 'vat') {
      return 'percent';
    }
    if (field === 'price_with_vat_pu') {
      return 'currency';
    }
    if (field === 'price_with_vat_total') {
      return 'currency';
    }
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(orderItem: SupplierOrderItem, field: string): any {
    if (field === 'price_with_vat_pu') {
      return this.moneyService.addVat(orderItem.price, orderItem.vat, 2);
    }
    if (field === 'price_with_vat_total') {
      // console.log('getCalculatedField', orderItem.price, orderItem.vat, orderItem.qty);
      return this.moneyService.addVat(orderItem.price, orderItem.vat, 2) * orderItem.qty;
    }
    if (field === 'vat') {
      return this.moneyService.basicPointsToPercent(orderItem.vat);
    }
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  setCalculatedField(orderItem: SupplierOrderItem, modifiedText: string, field: string): void {
    modifiedText = this.cleanInputToString(modifiedText, true);
    const inputAsNumber = Number(modifiedText);
    if (field === 'price_with_vat_pu') {
      orderItem.price = this.moneyService.removeVat(inputAsNumber, orderItem.vat);
      this.saveItem(orderItem);
      return;
    }
    if (field === 'price_with_vat_total') {
      orderItem.price = +(this.moneyService.removeVat(inputAsNumber, orderItem.vat) / orderItem.qty).toFixed(10);
      this.saveItem(orderItem);
      return;
    }
    if (field === 'vat') {
      orderItem.vat = this.moneyService.percentToBasicPoints(inputAsNumber);
      this.saveItem(orderItem);
      return;
    }
    throw Error(`setCalculatedField field: ${field} not found`);
  }

  addItemByCode(code: string): void {
    // console.log('addItemByCode', code);
    let getByCode$;

    for (const i of this.items) {
      if (i.productCode === code) {
        this.increaseQtyCounted(i);
        return;
      }
    }

    const progressDialogRef = this.dialog.open(ProgressDialogComponent, {});
    progressDialogRef.componentInstance.titleI18nKey = 'Searching product';
    progressDialogRef.componentInstance.messageI18nKey = '';
    progressDialogRef.componentInstance.onCancel.subscribe(result => {
      if (getByCode$ != null) {
        getByCode$.unsubscribe();
      }
    });

    getByCode$ = this.posProductService.getOrCreateByCode(code).subscribe(r => {
      progressDialogRef.close();
      const product = r.item;

      if (r.created) {
        // Product just created from Shared data base, give the client the opportunity to set a price or other things
        // TODO: auto adjust height and width to the content
        const dialogRef = this.dialog.open(PosProductsDetailComponent, {
          maxHeight: '90vh',
          minWidth: '60%',
          maxWidth: '90%',
          panelClass: 'no-padding-dialog-container',
          data: {
            itemId: product.id,
            extraMessageI18nKey: 'PRODUCT_JUST_ADDED_TO_THE_BUSINESS_DB_EDIT_BEFORE_ADDING_TO_THE_SALE',
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          const p: Product = result;
          this.addOrderItemFromProduct(p);
        });
      } else {
        if (product == null) {
          this.createNewProductAndAddIt(code);
        } else {
          this.addOrderItemFromProduct(product);
        }
      }
    }, error => {
      progressDialogRef.close();
      this.createNewProductAndAddIt(code);
    });
  }

  increaseQtyCounted(orderItem: SupplierOrderItem): void {
    orderItem.qty++;
    this.posSupplierOrdersItemService.modify(orderItem.id, {qty: orderItem.qty}).subscribe();
  }

  onBarCodeScanner(code: string): void {
    this.addItemByCode(code);
  }

  addByCodeDialog(): void {
    const dialogRef = this.dialog.open(SimpleTextDialogComponent, {});
    dialogRef.componentInstance.showBarCodeButton = true;
    dialogRef.componentInstance.hintI18N = 'CODE';
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 1) {
        this.addItemByCode(result);
      } else {
        this.notificationService.warning('EMPTY_CODE');
      }
    });
  }

  createNewProductAndAddIt(code?: string): void {
    const dialogRef = this.dialog.open(PosProductsDetailComponent, {
      maxHeight: '90vh',
      minWidth: '60%',
      maxWidth: '85%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'floating-selector',
        code
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const p: Product = result;
      this.addOrderItemFromProduct(p);
    });
  }

  searchExistingProductDialog(): void {
    const dialogRef = this.dialog.open(PosProductsListComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'floating-selector'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const p: Product = result;
      this.addOrderItemFromProduct(p);
    });
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return !this.isEditing;
  }

  addOrderItemFromProduct(p: Product): void {
    if (p == null) {
      return;
    }
    const newOrderItem = SupplierOrderItem.createFromProduct(p);
    this.addItemEnd(newOrderItem);
  }

}
