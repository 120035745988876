<div class="base-paddings">
  <div fxLayoutGap="20px" fxLayout="row" fxLayout.lt-md="column">
    <div fxFlex="30" fxFlex.lt-md="grow" fxLayoutGap="10px" fxLayout="column">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title class="pb1">{{ "QUICK_ACTIONS" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <!-- New BSS button -->
          <div fxLayoutGap="18px" fxLayout="column">

            <button
              mat-stroked-button
              data-cy="new-bss"
              color="primary"
              [routerLink]="['/new_bss_stepper']">
<!--                <mat-icon aria-hidden="true">add_circle_outlined</mat-icon>-->
              {{ 'NEW_SERVICE_SHEET' | translate }}
            </button>

            <!-- New Order button -->
            <button mat-stroked-button data-cy="new-order" [routerLink]="['/pos/orders/create']">{{ 'NEW_SALE' | translate }}</button>

            <!-- New Client button -->
            <button mat-stroked-button data-cy="new-client" [routerLink]="['/clients/create']">{{ 'NEW_CLIENT' | translate }}</button>
            <!-- New Product button -->
            <button mat-stroked-button data-cy="new-product" [routerLink]="['/pos/products/create']">{{ 'NEW_PRODUCT' | translate }}</button>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined" *ngIf="unfinishedWorkSessions?.length > 0">
        <mat-card-header>
          <mat-card-title class="pb1">{{ "STARTED_TIMERS" | translate }}
            <button mat-icon-button matTooltip="{{ 'UPDATED_EVERY_FEW_SECONDS' | translate }}">
              <small><mat-icon>info</mat-icon></small>
            </button>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <!-- started timers, move this to a custom component? -->
          <div fxLayoutGap="18px" fxLayout="column">
            <span *ngFor="let timer of unfinishedWorkSessions">
              <a [routerLink]="['/', 'servicesheets', timer.serviceSheet]" class="link-action">{{ getTotalTimeString(timer.totalBssTime) }} - {{ timer.bssBikeName }}<span *ngIf="timer.assignedTo?.length > 0"> ({{ timer.assignedTo }})</span></a>
            </span>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- TODO: replace show for ngIf, and then we will save the api call, also add privacy option to not show this if the client doesnt wants to (like a bank) -->
      <ng-container *ngIf="this.userMe$ | async as userMe">
        <mat-expansion-panel *ngIf="userMe.hasAccessToBusinessData" [expanded]="!isMobile">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-card-title class="pb1">{{ "ANALYTICS" | translate }}</mat-card-title>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-analytics-crm></app-analytics-crm>
        </mat-expansion-panel>
      </ng-container>
    </div>

    <ng-container *ngIf="this.userMe$ | async as userMe">
    <div fxFlex="70" fxFlex.lt-md="grow">
      <mat-card appearance="outlined">
        <div fxLayout="row wrap" fxLayoutAlign="space-around" class="m2">
          <button mat-button
                  [routerLink]="['/servicesheets']" [queryParams]="{closed: true, payment_status: 'thisisalist,pen,par'}">
            {{ "CTX_DASHBOARD.ALL_SERVICE_SHEETS_PENDING_PAYMENT" | translate }} →
          </button>
          <button mat-button
                  [routerLink]="['/servicesheets']" [queryParams]="{closed: true, check_in_status: 'cin'}">
            {{ "CTX_DASHBOARD.ALL_SERVICE_SHEETS_PENDING_PICK_UP" | translate }} →
          </button>
          <button mat-button
                  [routerLink]="['/servicesheets']" [queryParams]="{closed: false}">
            {{ "CTX_DASHBOARD.ALL_SERVICE_SHEETS_OPEN" | translate }} →
          </button>
        </div>

        <mat-divider></mat-divider>

        <mat-card-header>
          <mat-card-title class="pb1">{{ "PENDING_SERVICE_SHEETS" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-servicesheet-list minimal="true" [defaultFilters]="{'closed': 'false'}"></app-servicesheet-list>
        </mat-card-content>
      </mat-card>
    </div>
    </ng-container>
  </div>

</div>
